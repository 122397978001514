.arrowWrapper {
  width: 28px;
  height: 28px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background: rgba(253, 230, 209, 0.5);
  }
}

.textArea {
  box-shadow: none !important;
  border: none !important;
  textarea {
    padding: 9px 8px !important;
    border: 1px solid #c8ced9 !important;
    border-radius: 6px;
    background: #ffffff;
    height: 96px !important;
    box-shadow: none !important;
    color: #1d1d1b !important;
    resize: none !important;

    * {
      padding-inline: 0;
      letter-spacing: 0.07em;
    }

    &::placeholder {
      font-family: Open Sans;
      color: #c8ced9 !important;
      font-weight: 400;
      font-size: 16px;
    }

    &:hover,
    &:focus {
      border: 1px solid rgba(29, 29, 27, 0.5) !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.input {
  padding: 13px 16px !important;
  border: 1px solid #c8ced9;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  box-shadow: none !important;
  color: #1d1d1b;

  &::placeholder {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }

  &:focus {
    border: 1px solid #1d1d1b;
    box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1);
  }
}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 6px 6px;
}

.createBtn {
  width: 283px !important;
  background-color: #ea7600 !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;
  height: 48px !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}

.cancelBtn {
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  width: 283px !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
  height: 48px !important;
  span {
    display: flex !important;
    align-items: center !important;

    img {
      margin-right: 9px;
    }
  }

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.fieldTitle {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 16px;
  color: #1d1d1b;
  margin-bottom: 8px;
  line-height: 22px;
}

.dropdown {
  background: #ffffff !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px !important;
  height: 48px !important;
  box-shadow: none;
  padding: 0 16px 0 8px !important;

  // * .k-picker-solid:focus {
  //   box-shadow: none !important;
  // }

  span {
    // padding-left: 8px !important;
  }
  *:focus {
    box-shadow: none !important;
  }
}

.customButton {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(#2260ff, #2260ff);
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  height: 40px;

  :disabled {
  }
}

.disabled {
  color: #c9c9c9;
  background: #e4e4e4 !important;
  border-radius: 4px;
  box-shadow: none !important;
}

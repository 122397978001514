.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 92px;
  height: 92px;
  border: 1px solid rgba(234, 118, 0, 0.1);
  border-radius: 50%;
  position: relative;
  animation: circle 2s linear infinite;
}

.spinner {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 15px;
  left: 15px;
  // transform: translate(-50%, -50%);
  border: 2px solid rgba(234, 118, 0, 0.4);
  border-radius: 50%;
  border-top: 2px solid #ea7600;
  animation: spin 2s linear infinite;
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  background: #ea7600;
  border-radius: 50%;
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    width: 28px;
    height: 28px;
  }

  50% {
    width: 48px;
    height: 48px;
  }

  100% {
    width: 28px;
    height: 28px;
  }
}

@keyframes spin {
  0% {
    width: 60px;
    height: 60px;
    rotate: 0deg;
  }

  50% {
    width: 80px;
    height: 80px;
  }

  100% {
    width: 60px;
    height: 60px;
    rotate: 360deg;
  }
}

@keyframes circle {
  0% {
    width: 92px;
    height: 92px;
  }

  50% {
    width: 112px;
    height: 112px;
  }

  100% {
    width: 92px;
    height: 92px;
  }
}
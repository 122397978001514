.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.classSettingsWrapper {
  margin-bottom: 52px;
}

.newModelBtn {
  color: #ea7600 !important;
  background-color: #fff !important;
  outline: #ea7600 !important;
  // padding: 7px 16px !important;
  cursor: pointer !important;
  border-radius: 6px !important;
  min-width: 230px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  // height: 40px !important;
  border: 1px solid #ea7600 !important;
  span {
    display: flex !important;
    align-items: center !important;
  }
}

.headerTitle {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 16px;
  color: #1d1d1b;
  line-height: 22px;
}

.linkCell {
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
  padding-left: 0px !important;
}

.headerClassName {
  border: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.checkedCheckbox {
  input {
    border-color: #1d1d1b !important;
    background-color: #1d1d1b !important;
    &:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
    &:checked:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.backWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  color: #56534d;
  line-height: 32px;
}

.img {
  margin-right: 8px;
}

.pageTitle {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
  color: #1d1d1b;
  line-height: 32px;

  span {
    color: #56534d;
    font-weight: 300;
  }
}

.button {
  width: 240px !important;
  height: 40px !important;
  background-color: #ea7600 !important;
  color: #fff !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.fieldset {
  border: none;
}

.enterCorrectionEntryText {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1d1d1b;
}

.topWrapper {
  padding: 20px 30px 16px;
  box-shadow: 0px 1px 0px 0px #dadada80;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formWrapper {
  padding: 20px 30px;
}

.title {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #1d1d1b;
}

.smallText {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #56534d;
}

.bottomWrapper {
  padding: 16px 30px;
  box-shadow: 0px -1px 8px 0px #0000000a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createBtn {
  width: 283px !important;
  background-color: #ea7600 !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;
  height: 48px !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}

.cancelBtn {
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  width: 283px !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
  height: 48px !important;
  span {
    display: flex !important;
    align-items: center !important;

    img {
      margin-right: 9px;
    }
  }

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.abbreviation {
  position: absolute;
  right: 16px;
  top: 13px;
  color: #56534D;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

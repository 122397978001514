.input {
  padding: 13px 16px !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  box-shadow: none !important;
  color: #1d1d1b;

  &::placeholder {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    border: 1px solid rgba(29, 29, 27, 0.5) !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
  }
}

//TODO: update styles, just an example
.error {
  border: 1px solid red !important;
  color: red !important;

  &:hover,
  &:focus {
    border: 1px solid red !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
  }
}

.formWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.loginFormWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginImageBlock {
  flex: 0.9;
  background: #1d1d1b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('../../img/logoBackground.png');
  background-size: cover;
}

.loginImage {
  width: 350px;
  height: 100%;
  z-index: 10;
}

.loginPattern {
  position: absolute;
  width: 100%;
  height: 100%;
}

.loginFormBlock {
  padding: 50px 50px 160px 50px;
  min-width: 80%;
}

.loginFormHeader {
  font-size: 40px;
  line-height: 60px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.loginFormDescription {
  margin-top: 4px;
  color: #1d1d1b;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.8;
}

.loginFormAction {
  margin-top: 150px;
  height: 40px;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .loginImageBlock {
    display: none;
  }

  .loginFormBlock {
    padding: 50px 20px 160px 20px;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

%link {
  font-family: Open Sans;
  font-size: 16px;
  margin-right: 28px;
  text-decoration: none;
  line-height: 24px;

  &:hover {
    background: rgba(200, 206, 217, 0.1);
    border-radius: 6px;
  }
  padding: 6px 0px;
}

.activeLink {
  @extend %link;
  font-weight: 400;
  color: #1d1d1b;
  position: relative;
  text-shadow: 0px 0px 1px #1d1d1b;
}

.inactiveLink {
  @extend %link;
  font-weight: 400;
  color: rgba(86, 83, 77, 0.5);
}

.activeBorder {
  height: 2px;
  background: #ea7600;
  border-radius: 4px;
  position: absolute;
  bottom: -11.5px;
  z-index: 100;
  @media (max-width: 1120px) {
    bottom: -2px;
  }
}

.headerTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 18px 30px;
  box-shadow: 0px 1px 0px rgba(218, 218, 218, 0.5);
}

.modalTitle {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
  color: #1D1D1B;
  text-transform: capitalize;
}

.closeIconHandler {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
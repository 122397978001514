.pagerWrapper {
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 27px;
  padding-right: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px 0 0;
  // padding: 15px 20px 20px;
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  // left: 0;
}

.dropDownWrapper {
  display: flex;
  align-items: center;
}

.dropdown {
  width: 75px !important;
  height: 32px !important;
  margin-right: 10px !important;
  background-color: #fff !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px !important;
  padding: 0 !important;
  font-family: Open Sans !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1d1d1b !important;

  span {
    padding: 0 !important;
    padding-left: 5px !important;
  }

  button {
    padding: 0 !important;
    background-color: black;
    span {
      padding: 0 !important;
      padding-right: 5px !important;
    }
  }
}

%commonListItem {
  font-family: Open Sans;
  font-size: 16px;
  padding-right: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItem {
  @extend %commonListItem;
  color: '#1D1D1B';
  &:hover {
    background-color: #f0f0f0;
  }
}

.activeListItem {
  @extend %commonListItem;
  color: '#1D1D1B';
  background-color: #e27202;
  color: #fff;
  &:hover {
    background-color: #d96f04;
  }
}

.elementsProPageText {
  margin-right: 10px;
  font-family: Open Sans;
  color: rgba(29, 29, 27, 0.8);
  font-weight: 400;
  font-size: 14px;
}

.totalCount {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 14px;
  color: rgba(29, 29, 27, 0.8);
}

.arrowsWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.pageNumberWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 14px;
}

.activePageNumber {
  background-color: #ea7600;
  color: #ffffff;
}

.pages {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

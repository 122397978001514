.wrapper {
  width: 234px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0 23px;
  background-color: #1d1d1b;
  @media (max-height: 850px) {
    overflow-y: scroll;
  }
}

.sidebarWrapper {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding-left: 20px;
  padding-top: 100px;
  // width: 268px;
  // height: 100vh;
  position: relative;
  flex-shrink: 0;
  box-shadow: 1px 0px 6px rgba(79, 116, 143, 0.06);
  background-color: #f7f7f9;
}

.logoWrapper {
  padding: 0 20px;
  position: relative;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
}

.blockTitle {
  font-weight: 500;
  color: #646a87;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.activeBlockTitle {
  color: #2260ff;
}

.link {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  // line-height: 21px;
  color: #0f2041;
  margin-bottom: 10px;
  padding-left: 15px;
}

.sideLine {
  width: 8px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 8px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: rgba(34, 96, 255, 0.2);
}

.nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding-top: 55px;
}

%link {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 11px 0 11px 20px;
  font-family: Open Sans;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-right: 13px;
  }
}

.navLink {
  @extend %link;
  font-weight: 400;
  color: #c8ced9;
}

.activeNavLink {
  @extend %link;
  font-weight: 600;
  color: #ffffff;
  background: rgba(253, 230, 209, 0.1);
  border-radius: 0 8px 8px 0;
  border-left: 2px solid #ea7600;
  margin-right: 20px;
  padding-left: 18px;
}

.groupWrapper:not(:last-child) {
  margin-bottom: 60px;
}

.groupNameTitle {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  color: #c8ced9;
  margin-bottom: 16px;
  text-transform: uppercase;
  padding-left: 20px;
}

.footerWrapper {
  padding: 0 20px;
}

.langWrapper {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.langIcon {
  margin-right: 4px;
}

%langBtn {
  font-weight: 500 !important;
  font-size: 14px !important;

  &:hover::before,
  &:active::before,
  &:focus::after {
    opacity: 0 !important;
  }
}

.langButton {
  @extend %langBtn;
  color: rgba(255, 255, 255, 0.5) !important;

  &:hover {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.activeLangButton {
  @extend %langBtn;
  color: #fff !important;
}

.dividerForLangButton {
  height: 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.signOutWrapper {
  border-top: 1px solid rgba(218, 218, 218, 0.1);
  padding-top: 23px;
  display: flex;
  justify-content: space-between;
  font-family: Open Sans !important;
  font-weight: 500;
  font-size: 14px;
  color: #c8ced9;
  cursor: pointer;
  // display: flex;
  // justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  img {
    margin-left: 14px;
  }
}

.versionNumber {
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #c8ced9;
  right: 10px;
  top: 2px;
}

.warehouseWrapper {
  padding: 0 20px;
  width: 100%;
}

.warehouseNameWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.firstLetterWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
  color: #1d1d1b;
  background-color: #ea7600;
  border-radius: 4px;
}

.warehouseName {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  color: #c8ced9;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px 0 20px;
}

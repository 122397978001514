.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.currentQuantityText {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #56534d;
}

.quantityText {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #ea7600;
}

.newModelBtn {
  color: #ea7600 !important;
  background-color: #fff !important;
  outline: #ea7600 !important;
  // padding: 7px 16px !important;
  cursor: pointer !important;
  border-radius: 6px !important;
  min-width: 200px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  // height: 40px !important;
  border: 1px solid #ea7600 !important;
  span {
    display: flex !important;
    align-items: center !important;
  }
}

.item {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  border: none !important;
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
  height: 34px;
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
}

.out {
  color: #f1202e;
}

.in {
  color: #64ba69;
}

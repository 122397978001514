.headerClassName {
  border: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.qwe {
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.checkboxCell {
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
  padding-left: 16px !important;
}

.headerCell {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 34px !important;
}

.checkedCheckbox {
  input {
    border-color: #1d1d1b !important;
    background-color: #1d1d1b !important;
    &:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
    &:checked:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
  }
}

.arrowButtonWrapper {
  display: flex;
  align-items: center;
}

%arrowButton {
  border-radius: 2px !important;
  margin-right: 8px !important;
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }

  & > span {
    min-width: 8px !important;
    max-width: 8px !important;
    min-height: 4px !important;
    max-height: 4px !important;
    margin-top: 2px !important;
  }
}

.arrowButtonUp {
  @extend %arrowButton;
  background: #ea7600 !important;
  color: #fff !important;

  & > span {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
}

.arrowButtonDown {
  @extend %arrowButton;
  transform: rotate(270deg);
}

.arrowButtonLoader {
  @extend %arrowButton;
  padding: 4px !important;
  background: #ea7600 !important;
}

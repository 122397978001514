.wrapper {
  position: absolute;
  // top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: auto;
}

.spinner {
  width: 100px;
  text-align: center;

  div {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.7s infinite ease-in-out both;
    margin-right: 5px;
    background: #2260ff;
  }
}

.spinner .spinner__item1 {
  animation-delay: -0.6s;
}

.spinner .spinner__item2 {
  animation-delay: -0.4s;
}

.spinner .spinner__item3 {
  animation-delay: -0.2s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: translateY(-18px);
}
.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.fieldTitle {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 16px;
  color: #1d1d1b;
  margin-bottom: 8px;
  line-height: 22px;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createBtn {
  background-color: #ea7600 !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}

.deleteBtn {
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  width: 262px !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
  height: 48px !important;
  span {
    display: flex !important;
    align-items: center !important;
  }

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.deleteImg {
  margin-right: 9px;
}

.disabledCreateBtn {
  background-color: #dadada !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgba(86, 83, 77, 0.5) !important;
  cursor: auto;
}

.optionalText {
  color: rgba(86, 83, 77, 1);
  font-size: 14px;
}

%notification {
  margin: 34px 30px 0 0;
  border-radius: 6px!important;
  color: #1D1D1B!important;
  padding: 14px 10px 14px 14px!important;
  font-size: 16px!important;
  gap: 16px!important;
}

%notificationIcon {
  border-radius: 6px;
  padding: 8px;
  width: auto;
  height: auto;
  color: #fff;
}

%notificationCloseIcon {
  & > span:last-child {
    color: #56534D!important;

    & > span > span {
      font-size: 20px!important;
    }
  }
}

.successNotification {
  @extend %notification;
  background-color: #eff8f0!important;
  border: 1px solid #64BA69!important;

  & > span:first-child {
    @extend %notificationIcon;
    background: #64BA69;

    &::before {
      content: url(../../img/successNotificationIcon.svg);
      max-height: 20px;
    }
  }

  @extend %notificationCloseIcon;
}

.errorNotification {
  @extend %notification;
  background-color: #fde9ea!important;
  border: 1px solid #F1202E!important;

  & > span:first-child {
    @extend %notificationIcon;
    background: #F1202E;

    &::before {
      content: url(../../img/errorNotificationIcon.svg);
      max-height: 20px;
    }
  }

  @extend %notificationCloseIcon;
}

.wrapper {
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 30px;
}

.title {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
  color: #1d1d1b;
}

.email {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  color: #1d1d1b;
}

.arrow {
  margin-left: 12px;
}

.button {
  background-color: #ea7600 !important;
  color: #fff !important;
  height: 40px;
  width: 264px;
  border-radius: 6px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;

  &:hover {
    background: #ea7600;
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24);
    border-radius: 6px !important;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.back {
  cursor: pointer;
  margin-right: 16.5px;
  margin-top: 2px;
}

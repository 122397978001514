.linkWrapper {
  display: flex;
  align-items: center;
  padding: 24px 20px;
}

.backWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  color: #56534d;
  line-height: 32px;

  img {
    margin-right: 8px;
  }
}

.pageTitle {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
  color: #1d1d1b;
  line-height: 32px;

  span {
    color: #c8ced9;
  }
}

.groupTitle {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  color: #1d1d1b;
  margin-bottom: 16px;
  line-height: 22px;
}

.input {
  padding: 13px 16px !important;
  border: 1px solid #c8ced9;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  box-shadow: none !important;
  color: #1d1d1b;

  &::placeholder {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }

  &:focus {
    border: 1px solid #1d1d1b;
    box-shadow: 0px 1px 3px rgba(47, 43, 67, 0.1), inset 0px -1px 0px rgba(47, 43, 67, 0.1);
  }
}

.textAreaWrapper {
  margin-bottom: 24px;
  span {
    textarea {
      padding-inline: 0;
    }
  }
  span:focus {
  }
}

.textArea {
  padding: 9px 8px !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px;
  background: #ffffff;
  height: 96px !important;
  box-shadow: none !important;
  color: #1d1d1b;
  * {
    padding-inline: 0;
  }

  *::placeholder {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }

  :focus {
  }
}

.fieldTitle {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 16px;
  color: #1d1d1b;
  margin-bottom: 7px;
  line-height: 22px;
}

%btnWrapper {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  box-shadow: 0px -1px 0px rgba(200, 206, 217, 0.5), 0px -1px 8px rgba(0, 0, 0, 0.04);
  z-index: 1;
}

.buttonsWrapper {
  @extend %btnWrapper;
  justify-content: center;
}

.singleButtonWrapper {
  @extend %btnWrapper;
  justify-content: space-between;
}

.createBtn {
  background-color: #ea7600 !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}

.disabledCreateBtn {
  background-color: #dadada !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: rgba(86, 83, 77, 0.5) !important;
  cursor: auto;
}

.deleteBtn {
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  width: 283px !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
  height: 48px !important;
  span {
    display: flex !important;
    align-items: center !important;
  }

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.deleteImg {
  margin-right: 9px;
}

.disabledDeleteBtn {
  opacity: 0.4 !important;
  cursor: default !important;

  &:hover {
    background: #fff !important;
    box-shadow: none !important;
  }
}

.dropdown {
  background: #ffffff !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px !important;
  height: 48px !important;
  padding: 0 16px 0 8px !important;
  box-shadow: none !important;

  *:focus {
    box-shadow: none !important;
  }
}

.fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 0 20px;
  height: calc(100% - 78px);
  overflow: auto;
}

.fieldsetWithoutButtons {
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 80px);
}

.fieldWrapper {
  margin-bottom: 24px;
  width: 48.6%;
  flex-shrink: 0;
}

.mainInfoWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hintText {
  font-size: 14px;
  color: #56534d;
}

.optionalText {
  color: rgba(86, 83, 77, 1);
  font-size: 14px;
}

.checkboxWrapper {
  margin-bottom: 42px;
}

.checkedCheckbox {
  input {
    border-color: #1d1d1b !important;
    background-color: #1d1d1b !important;
    &:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
    &:checked:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
  }
}

.contractWrapper {
  margin-bottom: 52px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerTitle {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 16px;
  color: #1d1d1b;
  line-height: 22px;
}

.newModelBtn {
  color: #ea7600 !important;
  background-color: #fff !important;
  outline: #ea7600 !important;
  // padding: 7px 16px !important;
  cursor: pointer !important;
  border-radius: 6px !important;
  min-width: 230px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  // height: 40px !important;
  border: 1px solid #ea7600 !important;
  span {
    display: flex !important;
    align-items: center !important;
  }
}

.contractNumberFieldWrapper {
  width: 48.6%;
  display: flex;
  align-items: center;

  div {
    width: 100%;
  }
}

%defaultSpinner {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  animation: spin 1.5s linear infinite;
  background: conic-gradient(
    from 46.16deg at 58.37% 50%,
    rgba(255, 255, 255, 0) -19.34deg,
    rgba(255, 255, 255, 0) 87.5deg,
    #ffffff 273.75deg,
    #ffffff 340.63deg,
    rgba(255, 255, 255, 0) 340.66deg,
    rgba(255, 255, 255, 0) 447.5deg
  );

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background: #ea7600;
    border-radius: 100%;
  }
}

.spinner {
  @extend %defaultSpinner;
}

.smallSpinner {
  @extend %defaultSpinner;
  width: 9px;
  height: 9px;
  margin-top: -4px;

  &::after {
    width: 5px;
    height: 5px;
  }
}

.updaterSpinner {

  background: conic-gradient(
    from 46.16deg at 58.37% 50%,
    #C8CED9 -19.34deg,
    #C8CED9 87.5deg,
    #ffffff 273.75deg,
    #ffffff 340.63deg,
    #C8CED9 340.66deg,
    #C8CED9 447.5deg
  );

  &::after {
    background: #fff;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

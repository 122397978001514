.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.inputWrapper {
  position: relative;
  height: 34px;
  width: 362px;
}

.icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 14.5px;
  width: 14.5px;
}

.newModelBtn {
  color: white !important;
  background-color: #ea7600 !important;
  outline: none !important;
  padding: 7px 16px !important;
  cursor: pointer !important;
  border-radius: 6px !important;
  border: none !important;
  min-width: 240px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  height: 40px !important;
  span {
    display: flex !important;
    align-items: center !important;
  }

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}

.deleteBtn {
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  width: 173px !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
  height: 40px !important;
  span {
    display: flex !important;
    align-items: center !important;
  }

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.img {
  margin-right: 9px;
}
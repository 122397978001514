.valueContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.valueAvatar {
  background-color: #EA7600;
  padding: 2px 8px;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  margin-right: 10px;
}

.popup {
  & > div {
    border-radius: 6px !important;
  }
}

%commonListItem {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  color: #1D1D1B;
  padding: 12px 15px;
  margin: 0 12px 16px;
  border: 1px solid #C8CED9;
  border-radius: 6px;
  cursor: pointer;
}

.listItem {
  @extend %commonListItem;
  &:hover {
    border: 1px solid #EA7600;
  }
}

.activeListItem {
  @extend %commonListItem;
  background-color: rgba(253, 230, 209, 0.2);
  border: 1px solid #EA7600;
}

.headerContainer {
  padding: 16px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.footerContainer {
  padding: 0 12px 16px;
}

.footerDivider {
  border-top: 1px solid #C8CED9;
  margin-bottom: 16px;
}

.footerButton {
  background-color: #1D1D1B!important;
  color: #fff!important;
  width: 100%!important;
  font-weight: 500!important;
}

.valueName {
  width: 176px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
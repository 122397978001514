// @import '~@progress/kendo-theme-default/dist/all.scss';
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('../fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url('../fonts/OpenSans-Medium.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url('../fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('../fonts/Nunito-Regular.ttf');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url('../fonts/Nunito-Regular.ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

// GLOBAL STYLE FOR SELECT
.k-animation-container {
  border-radius: 6px !important;

  .k-list-optionlabel {
    font-family: Open Sans !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #56534d !important;
    height: 44px;
  }

  .k-selected {
    background: rgba(253, 230, 209, 0.5) !important;
    &:hover {
      background: rgba(200, 206, 217, 0.2) !important;
    }
  }

  li {
    font-family: Open Sans !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #56534d !important;
    height: 44px;

    &[aria-selected='true'] {
      background: rgba(253, 230, 209, 0.5) !important;
    }

    &:hover {
      background: rgba(200, 206, 217, 0.2) !important;
    }
  }

  .k-list-item.k-focus {
    box-shadow: none !important;
  }

  .k-list-content,
  .k-popup,
  .k-list {
    border-radius: 6px !important;
    border: none !important;
  }
}

.k-i-caret-alt-down::before {
  content: '' !important;
}
.k-i-caret-alt-down {
  background-image: url('./img/dropdownIcon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.k-dropdownlist[aria-expanded='true'] {
  .k-i-caret-alt-down {
    background-image: url('./img/dropdownIconActive.svg') !important;
  }
}

.k-picker-solid:focus,
.k-picker-solid:focus-within,
.k-picker-solid.k-focus {
  box-shadow: none !important;
}
//

tr {
  &[data-is-selected='selected'] {
    background: #fffaf6 !important;
  }
  &:hover {
    background: rgba(200, 206, 217, 0.1) !important;
  }
  // style for header table separators
  th {
    border-inline-start-width: 1px !important;
    &:first-child {
      border-inline-start-width: 0 !important;
    }
  }
}

th {
  background: #f3f3f3 !important;
  vertical-align: inherit !important;
  padding: 6.5px 20px !important;
}

td {
  padding: 8px 0px 8px 20px !important;
  background-color: inherit;
}

// styles for nested table in table
.k-detail-row {
  & .k-hierarchy-cell {
    display: none;
  }

  & .k-detail-cell {
    padding: 0 !important;

    & .k-grid {
      border: none;

      & .k-grid-header {
        display: none;
      }

      & .k-grid-content {
        background-color: #fafafb;
      }
    }
  }
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: url('./img/checkboxMinusIcon.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 12px !important;
}

.k-grid-content.k-virtual-content {
  overflow: auto !important;
}

.k-grid-header.k-grid-draggable-header {
  padding: 0 !important;
}

//styles for border radius in the table
.k-grid .k-grid-header {
  border-radius: 6px 6px 0 0 !important;
}

.k-grid .k-grid-header-wrap {
  border-radius: 6px 6px 0 0 !important;
}

.k-grid .k-grid-container {
  border-radius: 0 0 6px 6px !important;
}

.k-calendar-th {
  background-color: #fff !important;
}

.k-calendar-th,
.k-calendar-td {
  padding: 0 !important;
  border: none !important;
}

.k-calendar.k-calendar-range {
  border-radius: 6px !important;
}

.k-calendar-header {
  margin-top: 10px;
}

.k-calendar-view {
  margin: 0 8px 12px !important;
}

.k-calendar-monthview .k-calendar-td {
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 37px) !important;
}

.k-calendar .k-calendar-view .k-today {
  // color: #ea7600;
  color: #1d1d1b !important;
  background: rgba(200, 206, 217, 0.2) !important;
  border: 1px solid #c8ced9 !important;
}

.k-calendar .k-range-mid {
  background-color: rgba(253, 230, 209, 0.4) !important;
}

.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: #ea7600 !important;
}

tr.k-grid-norecords {
  height: 50px !important;
}

.k-dateinput.k-input.k-input-md.k-input-solid.k-rounded-md {
  border: none !important;

  &:focus,
  &:focus-within {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  & > input {
    text-align: center;
    &:focus,
    &:focus-within {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.k-calendar-nav-today {
  display: none !important;
}

.k-calendar-header.k-hstack {
  position: relative;
  height: 40px;
}

.k-calendar-title {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.k-calendar-nav-prev {
  position: absolute !important;
  left: 15px;

  & > span {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #c8ced9 !important;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1)) !important;
    border-radius: 6px !important;
  }
}
.k-calendar-nav-next {
  position: absolute !important;
  right: 15px;

  & > span {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid #c8ced9 !important;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1)) !important;
    border-radius: 6px !important;
  }
}

tr {
  background-color: white !important;
}

.k-radio-label {
  margin-left: 8px !important;
}

.k-radio:after {
  width: 14px;
  height: 14px;
  border-radius: 16px;
  top: -1px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  outline: 1px solid #b4b4b4;
  border: 2px solid #ffffff;
  background-color: #ffffff;
}

.k-radio:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #1d1d1b;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #ffffff;
  outline: 1px solid #1d1d1b;
}

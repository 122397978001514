.dropdown {
  background: #ffffff !important;
  border: 1px solid #c8ced9 !important;
  border-radius: 6px !important;
  height: 48px !important;
  padding: 0 16px 0 8px !important;
  box-shadow: none !important;

  &::placeholder {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    border: 1px solid rgba(29, 29, 27, 0.5) !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
  }
}

.popup {
  & > div > div {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  }
}

.placeholder {
  * {
    font-family: Open Sans;
    color: #c8ced9 !important;
    font-weight: 400;
    font-size: 16px;
  }
}

.titleText {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;
  color: #1d1d1b;
  background: rgba(218, 218, 218, 0.5);
  height: 34px;
  box-sizing: border-box;
}

.itemText {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  color: #1d1d1b;
  border: none !important;
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
  height: 34px;
}

.headerCell {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.checkboxCell {
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
}

.checkedCheckbox {
  input {
    border-color: #1d1d1b !important;
    background-color: #1d1d1b !important;
    &:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
    &:checked:focus {
      box-shadow: 0 0 0 2px rgba(29, 29, 27, 0.3);
    }
  }
}

.linkCell {
  border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
  padding: 0 !important;
  padding-left: 11px !important;
}

.arrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background: rgb(235, 235, 235);
  }
}

.customHeaderCell {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 34px !important;
}

.headerClassName {
  border: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.qwe {
  background-color: rgba(218, 218, 218, 0.5) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.disabledRow {
  background-color: rgba(218, 218, 218, 0.1) !important;

  & > * {
    color: #c8ced9;
  }
}

.forbiddenCheckbox {
  input {
    border-color: rgba(200, 206, 217, 0.4) !important;
    background-color: rgba(200, 206, 217, 0.1) !important;
  }
}

.withDeterminationError {
  background-color: rgba(241, 32, 46, 0.03) !important;
  position: relative;
}

.nestedTableTr {
  td.nestedTableWrapper {
    padding-right: 15px !important;
    padding-bottom: 15px !important;
    padding-top: 0 !important;
    border-bottom: 1px solid rgba(218, 218, 218, 0.5) !important;
    div {
      div {
        div {
          display: inline-block !important;
        }
      }
    }
    tr {
      background-color: rgba(243, 243, 243, 0.3) !important;
    }
  }
}

.nestedTableRow {
  td {
    border-bottom: none !important;
  }
}

.lastItem {
  td {
    border-bottom: none !important;
  }
}

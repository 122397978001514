.wrapper {
  position: absolute;
  top: 30.8%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.img {
  margin: 0 auto 25px;
  text-align: center;
}

.createWarehouseText {
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
  color: #1d1d1b;
  margin-bottom: 16px;
  // text-transform: capitalize;
}

.createWarehouseSubtitleText {
  text-align: center;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  color: #1d1d1b;
  margin-bottom: 32px;
  line-height: 24px;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #ea7600 !important;
  color: #fff !important;
  height: 48px !important;
  border-radius: 6px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }

  span {
    display: flex;
    align-items: center;
  }
}

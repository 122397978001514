.wrapper {
  position: relative;
  padding: 30px;
}

.closeIcon {
  position: absolute;
  right: 35px;
  top: 30px;
  cursor: pointer;
}

.iconWrapper {
  text-align: center;
}

.title {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1d1d1b;
  margin-bottom: 16px;
  text-align: center;
}

.deleteText {
  font-family: Open Sans;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #56534d;
  margin-bottom: 24px;

  span {
    font-weight: 600;
  }
}

.deleteConfirmationText {
  font-family: Open Sans;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #56534d;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelBtn {
  width: 283px !important;
  background-color: #fff !important;
  border: 1px solid #1d1d1b !important;
  border-radius: 6px !important;
  color: #1d1d1b !important;
  padding: 7px 16px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  height: 48px !important;

  &:hover {
    background: rgba(29, 29, 27, 0.03);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.deleteBtn {
  width: 283px !important;
  background-color: #F1202E !important;
  border-radius: 6px !important;
  height: 48px !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #fff !important;

  &:hover {
    box-shadow: 0px 0px 12px rgba(234, 118, 0, 0.24) !important;
  }
}
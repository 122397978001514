.wrapper {
  background-color: rgba(53, 59, 63, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: auto;
}

.modalWrapper {
  width: 656px;
  // padding: 30px 30px 10px;
  background-color: #fff;
  min-height: 235px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 58.1%;
}

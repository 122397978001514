.table {
  border: 1px solid;
  border-color: rgb(239, 239, 239) !important;;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  border-radius: 4px;
}

.tr {
  height: 40px;
  padding: 0 15px;
  border-bottom: 1pt solid rgb(239, 239, 239);
}

.thead {
  :first-child {
    background-color: rgb(239, 239, 239) !important;
    border: none !important;
  }
}

.th {
  
  &:first-child {
    padding-left: 15px;
  }
  &:has(button) {
    text-align: center;
    button {
      background: #edf8fe;
      border: 2px solid #afd8ff;
      border-radius: 4px;
      padding: 2px 13px;
      margin: 0 5px;
      color: #318dff;
      // align-items: center;
      // align-content: center;
      // margin-right: 20px;
      cursor: pointer;
      // -color: red;
      // display: flex;
      // justify-content: center;
    }
  }
}

.dataPicker {
  height: 48px;
  border-color: #c8ced9 !important;

  & input {
    padding: 13px 16px !important;
    font-size: 16px !important;

    &::placeholder {
      color: #c8ced9;
    }
  }

  &:focus-within,
  &:hover {
    border: 1px solid rgba(29, 29, 27, 0.5) !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;

    & img {
      opacity: 1;
    }
  }
}

.toggleButton {
  padding: 0 17px;
  padding-inline: 17px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  border: none !important;
  background: inherit !important;

  & > img {
    min-width: 14px !important;
    min-height: 14px !important;
    opacity: 0.5;
  }
}
